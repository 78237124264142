import React from 'react'

import Icon from './wallet-icon.png'
import './index.scss'
import { Link } from 'gatsby'

const SparkleWalletIcon = () => {
    return <Link to='/user/wallet' style={{ textDecoration: 'none' }}><div className='sparkle-wallet-icon'>
        <img src={Icon} width = {30} height = {30}/>
        WALLET
    </div>
    </Link >
}

export default SparkleWalletIcon