import React, { useEffect } from "react"


import checkoutDetection from "../services/react-native/checkoutDetection"


import IndexPage from "../components/MasterIndexPage"

const Application = () => {
  useEffect(() => {
    checkoutDetection()
  }, [])

  if (typeof window !== "undefined" && window.ReactNativeWebView) {
    //
    let location = window.location.pathname

    if (location === "/express")
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "SPARK_EXPRESS",
        })
      )
    else
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "SPARKLE",
        })
      )
  }

  return (
    <>
      <IndexPage />
    </>
  )
}

const App = ({ data }) => {
  return <Application />
}

export default App
