import React, { useEffect, useContext, useState } from "react"

import loadable from '@loadable/component'


import Bills from "../assets/images/sparkle-logos/bills.webp"
import Load from "../assets/images/sparkle-logos/load.webp"
import Voucher from "../assets/images/sparkle-logos/voucher.webp"


import Spark from "../assets/images/Mascot_Stand_Black_Stroke.webp"

import PoweredByPldt from "../components/PoweredByPldt"

import { Link, navigate } from "gatsby"
import Container from "@material-ui/core/Container"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { LazyLoadImage } from "react-lazy-load-image-component"

import "../assets/css/masterindexpage.css"

import InputBase from "@material-ui/core/InputBase"

import SparkOnAir from "../components/SparkOnAir"





// import { searchProducts } from '../api/public/search'

import { LoginState } from "./globalstates"
import { isLoggedIn } from "../services/auth"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"

import SparkleWalletIcon from "./icons/SparkleWallet"

const Promotions = loadable(() => import('./Promotions/Promotions'))
const Services = loadable(() => import('../components/SparkleServices'))


// import { sentryCatchException } from "../services/sentry/sentry"

// import TestAdd from "./Homemade/ShopPage/testAdd"

const useStyles = makeStyles((theme) => ({
  control: {
    padding: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    background: "white",
  },
  font: {
    fontFamily: "visby",
    position: "absolute",
    fontWeight: "bold",
    paddingTop: "2.6rem",
    fontSize: "13px",
    paddingLeft: ".1rem",
  },
}))


const MasterIndexPage = () => {
  const [_, setisLoggin] = useContext(LoginState)


  useEffect(() => {
    let isLogin = isLoggedIn()

    setisLoggin(isLogin)
  }, [])




  // window.addEventListener("message", (message) => {
  //   alert(message.data)
  // })

  useEffect(() => {
    // window.addEventListener("message", (message) => {
    //   alert(message.data)
    // })

    let mounted = true
    //open dialog here

    if (mounted && typeof window !== 'undefined') {
      window.routeToPage = function (path) {
        navigate(path)
      }
    }

    return () => {
      mounted = false
    }
  }, [])



  return (
    <div className="page ">
      <Container component="main" maxWidth="xs" className="page">
        {/* <NeedHelp /> */}
        {/* {messengerOpen || !installed ? <InstallOptionDialog /> : ""} */}
        <div className="heading">
          <div className="heading-bg">
            <div className="heading-greetings">
              <div>
                <img
                  className="heading-logo" //animate__bounceIn
                  src={Spark}
                  alt="Spark Waving burstless"
                  height="100px"
                  width="100px"
                />
              </div>
              <div>
                <div className="heading-text animate__bounceIn">
                  <h1 className="heading-header">Sparkling Day, Juan!</h1>
                  What would you like to do for today?
                </div>
              </div>
            </div>
            <div className="heading-search-container">
              <div className="heading-search-shape">
                <InputBase
                  disabled={true}
                  style={{
                    width: "100%",
                    fontFamily: "montserrat",
                    fontSize: "1em",
                    fontWeight: "500",
                    color: "#6b6b6b",
                    paddingLeft: "0.3em",
                    zIndex: 999,
                  }}
                  placeholder="Search for products here"
                  onClick={() => {
                    navigate("/search")
                  }}
                // onKeyPress={(e) => {
                //   // alert(e.key)

                //   if (e.key === 'Enter') {
                //     navigate('/search')
                //   }
                // }}
                />
              </div>
              <SparkleWalletIcon />
            </div>
          </div>
        </div>

        {/* Services Tiles */}

        {/* Promotion banners */}
        <PoweredByPldt />
        {/* <Button
              onClick={async () => {
                let updateObj = {
                  paymongoRefundResourceID: "TEST",
                }

                return await updateVortexByRefId({
                  refId: "TEST",
                  data: updateObj,
                })
              }}
            >
              Throw Error
            </Button> */}


        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>

            <Link
              to="/vortex/billspayment" >
              <LazyLoadImage
                src={Bills}
                alt="Bills"
                effect="blur"
                width="100px"
                height="100px"
              />
            </Link>
            <div className="menu--text">Bills</div>
          </div>


          <div style={{ display: 'flex', flexDirection: 'column' }}>

            <Link
              to="/vortex/topup">
              <LazyLoadImage
                src={Load}
                alt="Load"
                effect="blur"
                width="100px"
                height="100px"
              />
            </Link>
            <div className="menu--text">Load</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', opacity: 0.4 }}>
            <LazyLoadImage
              src={Voucher}
              alt="Voucher"
              effect="blur"
              width="100px"
              height="100px"
            />
            <div className="menu--text">Vouchers</div>
          </div>

        </div>
        <Stack m={1} direction={"row"} justifyContent={"center"}>
          <Button
            onClick={() => {
              navigate("/vortex/transactions")
            }}
          >
            View transactions
          </Button>
        </Stack>

        <Services />


        <div
          style={{
            fontFamily: "montserrat",
            marginTop: "1em",
            marginBottom: "1em",
            textAlign: "center",
            fontWeight: "500",
          }}
        >
          Offers you may like
        </div>

        <Promotions />

      
      <SparkOnAir/>
      </Container>
    </div >
  )
}

export default MasterIndexPage
