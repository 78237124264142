import React from "react"
import "./index.scss"
import SparkOnPlane from "../../assets/images/spark_plane.webp"
import { Link } from "gatsby"

const SparkOnAir = () => {
  return (
    <div className='spark-on-air-wrapper'>
      <div className="spark-on-air">
        <Link to='/air'>
          <img src={SparkOnPlane} className="spark-on-air--image" />
          <div className="spark-on-air--card">Spark on Air</div>
        </Link>
      </div>
    </div>
  )
}

export default SparkOnAir
